export default {
  methods: {
    filterApply(form) {
      const filters = {};
      for (const prop in form) {
        
        const operator = form[prop].operator;
        const field = form[prop].field || prop;
        const parser = form[prop].parser;
        const value = parser ? parser(value, operator) : form[prop].value;

        if (value !== null && typeof value !== undefined) {
          if(!operator) {
            filters[field] = value
          }
        }
      }
      return filters;
    },
  },
};
